<template>
  <div class="content flex">
    <div class="tissue-pro flex-column">
      <div class="nameTit">组织项目</div>
      <div class="cont flex-1-auto">
        <el-tree
            class="filter-tree"
            :data="data"
            node-key="id"
            :props="defaultProps"
            default-expand-all
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
            ref="tree"
        >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span class="flex-center-v">
                  <el-button
                      v-if="(isAdmin || isTissueAdmin) && data.departmentType !== 'project'"
                      type="text"
                      size="mini"
                      @click.stop="() => addTissue(data)"
                  >新增组织</el-button
                  >
                  <el-button v-if="isAdmin || isTissueAdmin" type="text" size="mini" @click.stop="() => append(data)">编辑</el-button>
                  <el-button
                      v-if="((isAdmin || isTissueAdmin) && data.departmentType == 'project')&&(!data.ifCompletion)"
                      type="text"
                      size="mini"
                      @click.stop="() => remove(data)"
                  >完工</el-button
                  >
                  <el-button
                      v-if="((isAdmin || isTissueAdmin) && data.departmentType == 'project')&&(!!data.ifCompletion)"
                      type="text"
                      size="mini"
                      @click.stop="() => removes(data)"
                  >复工</el-button
                  >
                </span>
              </span>
        </el-tree>
      </div>
    </div>
    <div class="tissue-user flex-column">
      <div class="nameTit flex-center-v flex-space-between" style="padding-right: 20px">
        <div class="flex-center-v">
          <span>组织用户</span>
          <template v-if="nodedata">
            <el-button
                type="primary"
                size="mini"
                plain
                style="margin-left: 20px"
                @click="chooseUser"
            >选择用户</el-button
            >
            <el-button
                type="danger"
                size="mini"
                plain
                style="margin-left: 20px"
                @click="romoverUser"
            >移除用户</el-button
            >
          </template>
        </div>
        <div class="flex-center-v">
          <el-input v-model="nickname" placeholder="请输入用户名称" clearable size="mini" style="margin-right:15px;width: 150px;"></el-input>
          <el-select v-model="roleType" placeholder="请选择角色类型" size="mini" clearable style="width: 150px">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in typeList" :label="item.name" :value="item.id" :key="item.value"></el-option>
          </el-select>
          <el-button type="primary" size="mini" style="margin-left: 20px" @click="roleTypeSearch">查询</el-button>
        </div>
      </div>
      <div class="cont flex-1-auto">
        <el-table
            ref="multipleTable1"
            :data="userList"
            height="100%"
            style="width: 100%"
            @selection-change="selectItem1"
            tooltip-effect="light myTooltips"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column label="姓名" prop="nickname"></el-table-column>
          <el-table-column label="手机号码（账号）" prop="username"></el-table-column>
          <el-table-column label="角色" prop="roleNameStr"></el-table-column>
          <el-table-column label="备注" prop="remarks" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 新增组织弹框 -->
    <el-dialog title="新增组织" :visible.sync="isShowAddTissue" width="30%" @close="TissueFormClose">
      <el-form ref="addTissueForm" :model="addTissueForm" :rules="tissueRules" class="demo-form-inline" label-width="80px">
        <el-form-item label="组织名称" prop="name">
          <el-input v-model="addTissueForm.name" placeholder="请输入组织名称"></el-input>
        </el-form-item>
        <el-form-item label="组织类型" prop="departmentType">
          <el-select
              v-model="addTissueForm.departmentType"
              :disabled="isEdit"
              placeholder="请选择组织类型"
              style="width: 100%"
          >
            <el-option label="分公司" value="branch"></el-option>
            <el-option label="项目部" value="project"></el-option>
            <el-option label="集团" value="company" disabled></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组织编码" name="departCoding">
          <el-input v-model="addTissueForm.departCoding" placeholder="请输入组织编码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmTissue" size="small">确 定</el-button>
        <el-button @click="isShowAddTissue = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择用户弹框 -->
    <el-dialog title="选择用户" @close="close" :visible.sync="isShowChooseUser" width="40%" class="choose-user">
      <div class="flex-center-v" style="padding-bottom: 10px;margin-bottom: 10px;border-bottom: 1px solid #dcdcdc;">
        <el-input v-model="changeUserForm.nickname" placeholder="请输入用户名称" clearable style="margin-right:15px;width: 250px;"></el-input>
        <el-button type="primary" style="margin-left: 20px" @click="changeUserSearch">查询</el-button>
      </div>
      <el-form ref="chooseForm" :model="chooseForm" :rules="chooseRules" class="demo-form-inline" label-width="80px">
        <el-form-item label="用户角色" prop="roleIdList">
          <el-select v-model="chooseForm.roleIdList" multiple placeholder="请选择用户角色" style="width: 100%">
            <el-option v-for="item in typeListCan" :label="item.name" :value="item.id" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="height: 500px; overfloy-y: auto">
        <el-table
            ref="multipleTable2"
            :data="changeUserList"
            height="100%"
            style="width: 100%"
            @selection-change="selectItem2"
        >
          <el-table-column align="center" type="selection"></el-table-column>
          <el-table-column align="center" prop="nickname" label="姓名"></el-table-column>
          <el-table-column align="center" prop="username" label="手机号码"></el-table-column>
        </el-table>
      </div>
      <div class="flex-center" style="padding-top: 10px">
        <el-pagination
            v-if="userDataTotal > 10"
            background
            :current-page.sync="changeUserForm.pageCurrent"
            :page-size="changeUserForm.pageSize"
            :total="userDataTotal"
            layout="total, prev, pager, next"
            @current-change="handleSizeChangeUser"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="ChooseUserConfirm" size="small">确 定</el-button>
        <el-button @click="isShowChooseUser = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import apis from './apis2.js'
import { mapGetters } from 'vuex'
export default {

  name: 'institutional-framework',

  components: {
  },

  props: {
  },

  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label",
      },
      expandDefault: [],
      checkDefault: [],
      data: [],
      isShowAddTissue: false,
      addTissueForm: {
        departCoding: '',
        name: '',
        pid: '',
        code: '',
        departmentType: ''
      },
      tissueRules: {
        name: [{ required: false, message: '请输入组织名称', trigger: 'blur' }],
        departmentType: [{ required: false, message: '请选择组织类型', trigger: 'change' }],
        departCoding: [{ required: false, message: '请输入组织编码', trigger: 'change' }]
      },
      isEdit: false,
      userList: [],
      roleType: '',
      typeList: [],
      nodedata: undefined,
      typeListCan: [],
      isShowChooseUser: false,
      chooseForm: {
        roleIdList: [],
        departmentId: '',
        userId: ''
      },
      chooseRules: {
        roleIdList: [{ required: false, message: '请选择用户角色', trigger: 'change' }]
      },
      changeUserList: [],
      changeUserForm: {
        departmentId: '',
        nickname: '',
        pageCurrent: 1,
        pageSize: 10
      },
      userDataTotal: 0,
      multipleSelection1: [],
      multipleSelection2: [],
      nickname: ''
    }
  },

  computed: {
    ...mapGetters(['companyId', 'optionItem']),
    isAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("超级管理员")
    },
    isTissueAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("组织管理员");
    },
    isProjectAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("项目管理员");
    },
  },

  watch: {
    checkDefault(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          document.querySelector(".el-tree-node__content").click();
        });
      }
    },
    companyId() {
      this.init()
      this.getTypes()
    }
  },

  mounted() {
    setTimeout(() => {
      this.init()
      this.getTypes()
    }, 500)
  },

  methods: {
    async getTypes() {
      this.typeList = await apis.findByRoleType()
      // console.log(this.typeList)
    },
    async init() {
      this.data = await apis.getNzTree(this.companyId)
      // console.log(this.data)
    },
    // 1-新增组织弹框
    addTissue(data) {
      // console.log(data)
      this.tissueData = data
      this.addTissueForm.name = ''
      this.addTissueForm.departmentType = ''
      this.addTissueForm.departCoding = ''
      this.isEdit = false
      this.isShowAddTissue = true
    },
    // 确定弹框
    async confirmTissue() {
      if (this.isEdit) {
        await apis.updateZz(this.addTissueForm)
        this.init()
      } else {
        delete this.addTissueForm.id
        this.addTissueForm.pid = this.tissueData.id
        this.addTissueForm.code = this.tissueData.code
        this.addTissueForm.departCoding = this.tissueData.departCoding
        await apis.addZz(this.addTissueForm)
        this.init()
      }
      this.$message.success('操作成功')
      this.isShowAddTissue = false
    },
    async close(){
      this.changeUserForm.departmentId = ''
      this.changeUserForm.nickname = ''
      this.changeUserForm.pageCurrent = 1
      this.$refs.chooseForm.resetFields()
    },
    TissueFormClose() {
      this.$refs.addTissueForm.resetFields()
    },
    // 编辑
    append(data) {
      this.isEdit = true
      this.addTissueForm.name = data.label
      this.addTissueForm.id = data.id
      this.addTissueForm.departmentType = data.departmentType
      this.addTissueForm.departCoding = data.departCoding
      this.isShowAddTissue = true
    },
    // 完工
    remove(data) {
      this.$confirm('是否完成操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await apis.overZz(data.id)
        this.$message.success('操作成功')
        this.init()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 复工
    removes(data) {
      this.$confirm('是否完成操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await apis.reTurnZz(data.id)
        this.$message.success('操作成功')
        this.init()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 节点被点击事件
    async handleNodeClick(node) {
      // console.log(node)
      this.nodedata = node
      this.userList = await apis.findByDepartmentfindAll(this.nodedata.code, this.roleType,this.nickname)
      // console.log(this.userList)
    },
    // 1-选择用户
    async chooseUser() {
      // console.log(this.nodedata)
      const roleType = this.nodedata.departmentType == 'project' ? 'project' : 'company'
      this.typeListCan = await apis.findByRoleType(roleType)
      this.changeUserForm.departmentId = this.nodedata.id
      const data = await apis.getUserList(this.changeUserForm)
      this.changeUserList = data.list
      this.userDataTotal = data.total
      // console.log(data)
      this.isShowChooseUser = true
    },
    changeUserSearch() {
      this.handleSizeChangeUser(1)
    },
    async handleSizeChangeUser(val) {
      this.changeUserForm.pageCurrent = val
      const data = await apis.getUserList(this.changeUserForm)
      this.changeUserList = data.list
      this.userDataTotal = data.total
    },
    // 移除用户
    romoverUser() {
      if (this.multipleSelection1.length == 0) return this.$message.warning('请选择一个用户')
      const data = this.multipleSelection1[0]
      this.$confirm('是否移除该用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await apis.removeZzyh(data.id)
        this.$message.success('操作成功')
        this.userList = await apis.findByDepartmentfindAll(this.nodedata.code, this.roleType, this.nickname)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 1-移除用户选中
    selectItem1(rows) {
      if (rows.length > 1) {
        var newRows = rows.filter((it, index) => {
          if (index == rows.length - 1) {
            this.$refs.multipleTable1.toggleRowSelection(it, true);
            return true;
          } else {
            this.$refs.multipleTable1.toggleRowSelection(it, false);
            return false;
          }
        })
        this.multipleSelection1 = newRows;
      } else {
        this.multipleSelection1 = rows;
      }
      // console.log(this.multipleSelection1)
    },
    // 查询
    async roleTypeSearch() {
      this.userList = await apis.findByDepartmentfindAll(this.nodedata.code, this.roleType, this.nickname)
    },
    // 1-选择用户选中
    selectItem2(rows) {
      if (rows.length > 1) {
        var newRows = rows.filter((it, index) => {
          if (index == rows.length - 1) {
            this.$refs.multipleTable2.toggleRowSelection(it, true);
            return true;
          } else {
            this.$refs.multipleTable2.toggleRowSelection(it, false);
            return false;
          }
        })
        this.multipleSelection2 = newRows;
      } else {
        this.multipleSelection2 = rows;
      }
      // console.log(this.multipleSelection2)
    },
    async ChooseUserConfirm() {
      if (this.multipleSelection2.length == 0) return this.$message.warning('请选择一个用户')
      const data = this.multipleSelection2[0]
      this.chooseForm.userId = data.id
      this.chooseForm.departmentId = this.nodedata.id
      await apis.addZzyh(this.chooseForm)
      this.$message.success('操作成功')
      this.isShowChooseUser = false
      this.userList = await apis.findByDepartmentfindAll(this.nodedata.code, this.roleType, this.nickname)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
